.indicator_instant_sell {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 0%;
    height: 30px;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(244, 88, 67, 0.07)),
        to(rgba(244, 88, 67, 0.07))
    );
    background-image: linear-gradient(180deg, rgba(244, 88, 67, 0.07), rgba(244, 88, 67, 0.07));
}

.indicator_instant_buy {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 0px;
    height: 30px;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(45, 195, 79, 0.07)),
        to(rgba(45, 195, 79, 0.07))
    );
    background-image: linear-gradient(180deg, rgba(45, 195, 79, 0.07), rgba(45, 195, 79, 0.07));
}

.separator-right {
    height: 100%;
    border-right: 1px dotted #dbe2ea;
}

.separator-left {
    height: 100%;
    border-left: 1px dotted #dbe2ea;
}

.base-orders {
    padding-right: 0px;
    padding-left: 0px;
}

.div_transactions {
    margin-right: 0px;
    margin-left: 0px;
}

.transaction_row {
    position: relative;
    height: 30px;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
}

.order-price-amount {
    text-align: left;
    padding-top: 4px;
    height: 30px;
    color: #373846;
    font-size: 12px;
}
