.baseGrid.bot_parametrs {
    width: 100%;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-areas: ".";
    -ms-grid-columns: 0.8fr 0.8fr 1fr 0.4fr 0.4fr 0.5fr 0.6fr 0.6fr 0.6fr 0.6fr;
    grid-template-columns: 0.8fr 0.8fr 1fr 0.4fr 0.4fr 0.5fr 0.6fr 0.6fr 0.6fr 0.6fr;
}

.gridLine.bot {
    grid-column-start: 1;
    grid-column-end: 11;
}
