.dropdown-item,
.dropdown-link {
    cursor: pointer;
}

a,
a:hover {
    text-decoration-line: none !important;
}

.w-layout-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
}

.nav-link {
    -webkit-transition: font-size 400ms ease;
    transition: font-size 400ms ease;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.6px;
    cursor: pointer;
    white-space: pre;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: #727f8d;
    padding: 20px 5px;
    text-align: left;
    /* !TODO remove if you have more than one nav */
    margin-left: 50px !important;
    /*margin-left: auto;*/
    margin-right: auto;
}

.nav-link.dropdown {
    padding: 5px 0px 5px 16px;
    height: 100%;
    width: 100%;
}

.nav-link.more {
    padding-right: 6px;
}

.nav-link:hover {
    color: #000;
}

.nav-link.active-button-menu {
    color: #373846;
    font-weight: 600;
    letter-spacing: 0.3px;
}

@media (max-width: 991px) {
    .nav-link {
        padding-right: 1%;
        padding-left: 1%;
    }
    .nav-link.active-button-menu.w--current {
        padding-right: 2%;
        padding-left: 2%;
    }
}

@media (max-width: 767px) {
    .nav-link {
        display: block;
        margin-top: 0px;
        padding-right: 85px;
        padding-left: 40px;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
        background-image: linear-gradient(180deg, #fff, #fff);
    }
    .nav-link:hover {
        background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f8fa), to(#f7f8fa));
        background-image: linear-gradient(180deg, #f7f8fa, #f7f8fa);
    }
    .nav-link.active-button-menu {
        margin-top: 0px;
    }
    .nav-link.active-button-menu.w--current {
        margin-top: 0px;
    }
}

@media (max-width: 479px) {
    .nav-link {
        padding-left: 30px;
        text-align: left;
    }
    .nav-link:hover {
        background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f8fa), to(#f7f8fa));
        background-image: linear-gradient(180deg, #f7f8fa, #f7f8fa);
    }
    .nav-link.active-button-menu {
        margin-top: 0px;
        padding-top: 24px;
        padding-bottom: 24px;
        text-align: left;
    }
}

/* Global style for scrollbar */

::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.2);
}


/* !TODO Temporary while few items at nav*/
.nav-link:nth-child(1), .nav-link:nth-child(2), .nav-link:nth-child(3) {
    margin-right: 0 !important;
}
