.dropdown-text-inner {
    display: block;
    margin-left: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    float: none;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-family: "Open Sans", sans-serif;
    color: #5d6267;
    font-size: 13px;
    text-align: left;
}

.dropdown-text-inner.currency {
    padding-left: 16px;
}

.dropdown-text-inner:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ededf1), to(#ededf1));
    background-image: linear-gradient(180deg, #ededf1, #ededf1);
}

.margin-right {
    margin-right: 49px;
}

.user-role-text {
    padding-top: 10px;
    width: 80px;
    margin-right: 8px;
    font-family: "Open Sans", sans-serif;
    color: #373846;
}

.icon-arrow {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 0px;
    color: #727f8d;
}

.icon-arrow.user {
    position: absolute;
    margin-right: 24px;
    margin-left: 70px;
    margin-top: 14px;
}

.icon-arrow.noArrow,
.icon-arrow.user.noArrow {
    display: none !important;
}

.icon-arrow.pair {
    margin-right: 8px;
}

.icon-dropdown {
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    margin-right: 20px;
    width: 1em;
    height: 1em;
}

.icon-dropdown:before {
    content: "\e603";
}

.currency-text {
    width: auto;
    padding: 10px;
    margin-right: 10px;
    font-family: "Open Sans", sans-serif;
    color: #373846;
    font-size: 16px;
    font-weight: 600;
}

.dropdown-text {
    width: auto;
    padding: 10px;
    display: flex;
    font-family: "Open Sans", sans-serif;
    color: #373846;
    font-size: 20px;
    font-weight: 600;
    align-content: center;
    justify-content: center;
}

.dropdown-item {
    padding: 1%;
}

.market-logo {
    margin-top: 0px;
    border-radius: 100px;
}

.dropdown-item:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ededf1), to(#ededf1));
    background-image: linear-gradient(180deg, #ededf1, #ededf1);
}

.dropdown-item:active {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ededf1), to(#ededf1));
    background-image: linear-gradient(180deg, #ededf1, #ededf1);
}

div.dropdown > div.base-row.row > i.fas.fa-angle-down {
    transition: transform 200ms;
    display: block;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transform-style: preserve-3d;
}

div.show.dropdown > div.base-row.row > i.fas.fa-angle-down {
    transition: transform 200ms;
    display: block;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(180deg);
    transform-style: preserve-3d;
}

.dropdownOpen {
    z-index: 901;
}

.w-dropdown-list {
    position: absolute;
    background: #dddddd;
    display: none;
    min-width: 100%;
}

.dropdown-link-2 {
    padding: 16px 20px 16px 21px;
    font-family: "Open Sans", sans-serif;
}

.dropdown-link-2:hover {
    font-family: "Open Sans", sans-serif;
    color: #e98607;
}

.w-dropdown-list.w--open {
    display: block;
}

@media (max-width: 991px) {
    .dropdown-link-2 {
        font-size: 13px;
    }
}

@media (max-width: 479px) {
    .icon-arrow {
        margin-right: 24px;
    }
    .icon-arrow.pair {
        margin-right: 8px;
        padding-right: 3px;
    }
    .icon-arrow.left {
        margin-right: 10px;
    }
}
