.indicator-base {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 23%;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 8px;
    padding-bottom: 8px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 12px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
    background-image: linear-gradient(180deg, #fff, #fff);
    box-shadow: 0 0 20px 2px rgba(107, 115, 151, 0.15);
    cursor: default;
    box-shadow: 0 0 16px 2px rgba(107, 115, 151, 0.1);
}

.indicator-base.percent {
    z-index: 33;
    padding-left: 8px;
    padding-right: 8px;
}

.indicator-title {
    padding: 1px 10px 6px;
    font-family: "Open Sans", sans-serif;
    color: #727f8d;
    font-size: 12px;
    font-weight: 400;
}

.indicator-text {
    margin-top: 8px;
    padding: 4px 16px;
    font-family: "Open Sans", sans-serif;
    color: #373846;
    font-size: 16px;
    font-weight: 600;
}

.indicator-text.green-text {
    color: #1eaf40;
    font-weight: 600;
}

.indicator-text.red-text {
    color: #af1e1e;
    font-weight: 600;
}

.indicator-line {
    width: 90%;
}

.indicator-numbers {
    position: absolute;
    color: #18a038;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
}

.indicator-numbers._1 {
    padding-left: 124px;
}

.indicator-numbers._2 {
    padding-left: 106px;
}

.indicator-numbers._3 {
    padding-left: 98px;
}

.indicators-block {
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 0px;
    padding-right: 0px;
}

.indicator {
    max-width: 100%;
    width: 100%;
    height: 3px;
    margin-top: -3px;
    border-radius: 2px;
    background-color: #e1e3e6;
}

.indicator.green {
    max-width: 100%;
    margin-bottom: 0px;
    background-color: #1eaf40;
}

@media (max-width: 991px) {
    .indicator-base {
        width: 31%;
    }
    .indicator-title {
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media (max-width: 767px) {
    .indicator-base {
        width: 45%;
        height: 80px;
        margin-right: 9px;
        margin-left: 11px;
    }
    .indicator-numbers {
        margin-left: 0px;
    }
}

@media (max-width: 479px) {
    .indicator-base {
        width: 100%;
        margin: 4px 8px 0px;
        border-bottom: 1px solid #dbe2ea;
        border-radius: 0px;
        box-shadow: none;
    }
    .indicator-title {
        font-size: 12px;
        text-align: center;
        letter-spacing: 0px;
    }
}
